import * as React from "react"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { Helmet } from "@life-without-barriers/react-components"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { LocalImage } from "@life-without-barriers/gatsby-common"

interface Props {
  title: string
  description: string
  path: string
  siteUrl: string
  image?: IGatsbyImageData
  isArticle?: boolean
  articleDate?: string
  geoPosition?: string
  includeGoogleSchema?: boolean
  themeColor?: string
}

interface ImageQuery {
  image: LocalImage
}

const WrappedHelmet = ({
  title,
  description,
  path,
  siteUrl,
  image,
  isArticle,
  articleDate,
  geoPosition,
  includeGoogleSchema,
  themeColor
}: Props) => {
  const data = useStaticQuery<ImageQuery>(graphql`
    query {
      image: file(relativePath: { regex: "/OG-image-dis.jpg/" }) {
        childImageSharp {
          gatsbyImageData(width: 800, quality: 90)
        }
      }
    }
  `)

  return (
    <Helmet
      title={title}
      description={description}
      path={path}
      siteUrl={siteUrl}
      image={image ?? getImage(data.image.childImageSharp.gatsbyImageData)}
      isArticle={isArticle}
      articleDate={articleDate}
      geoPosition={geoPosition}
      includeGoogleSchema={includeGoogleSchema}
      themeColor={themeColor}
    />
  )
}

export default WrappedHelmet
