import {
  Theme,
  media,
  ContactUsEmailLink,
  ResourcesTile,
  Interfaces,
  Container,
  Banner,
  Heading,
  Section,
  Row,
  Box,
  IconSheets,
  NavigationTiles,
  routesObject
} from "@life-without-barriers/react-components"
import { contentfulHelpers as Contentful } from "@life-without-barriers/utilities"
import { Site, getGatsbyImage } from "@life-without-barriers/gatsby-common"
import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../../../components/disability/Layout"
import {
  OurApproachToDisabilityServices,
  YourRights,
  OurDisabilityServices
} from "../../../components/disability/RelatedPagesContent"
import WrappedHelmet from "../../../components/disability/WrappedHelmet"

const { disabilityTheme } = Theme

interface PageResources {
  title: string
  pageImages: Contentful.RemoteImageAsset[]
}

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    uncrEasyEnglish: Interfaces.FileProps
    pageResources: {
      edges: [
        {
          node: PageResources
        }
      ]
    }
  }
}

const bannerBreadcrumbs = [
  routesObject.disabilityHome,
  {
    to: "/disability/our-approach/",
    text: "Our approach to disability services",
    title: "Return navigation"
  }
]

const dsacResources = [
  {
    name: "National Companion Card scheme",
    url: "http://www.companioncard.gov.au/"
  },
  {
    name: "Don’t dis my ability campaign",
    url: "http://www.dontdismyability.com.au/"
  },
  {
    name: "Access to public disabled toilets (MLAK System)",
    url: "http://www.masterlocksmiths.com.au/mlak.php"
  },
  {
    name: "My Health Record",
    url: "https://myhealthrecord.gov.au  "
  },
  {
    name: '"I\'m Okay" - Emergency Readiness for People with Disability',
    url: "http://imokay.org.au/"
  }
]

const dsacMembers = [
  {
    name: "John Green (Chair)",
    position: "Northern NSW Representative",
    imageTitle: "John-Green"
  },
  {
    name: "Christine Conway",
    position: "Hunter NSW Representative",
    imageTitle: "Christine-Conway"
  },
  {
    name: "Daniel McKenzie",
    position: "NSW Representative",
    imageTitle: "Daniel-McKenzie"
  },
  {
    name: "Macarthur Yee",
    position: "VIC Representative",
    imageTitle: "Macarthur-Yee"
  },
  {
    name: "Brad Mitchell",
    position: "TAS Representative",
    imageTitle: "Brad-Mitchell"
  },
  {
    name: "James Gardiner",
    position: "QLD Representative",
    imageTitle: "James-Gardiner"
  },
  {
    name: "Angelique Martin",
    position: "NT Representative",
    imageTitle: "Angelique-Martin"
  },
  {
    name: "Katie Soulos",
    position: "WA Representative",
    imageTitle: "Katie-Soulos"
  },
  {
    name: "Jennifer Coates",
    position: "NSW Representative",
    imageTitle: "Jennifer-Coates"
  }
]

const StyledLi = styled.li`
  padding-right: 0;
  ${media.medium`
    &:nth-child(odd) {
      padding-right: 1rem;
    }
    &:nth-child(even) {
      padding-left: 1rem;
    }
  `}

  ${media.large`
    padding-right: 1.5rem;
  `}
`

const getImageFromPageResources = (
  title: string,
  pageImages: Contentful.RemoteImageAsset[]
) => {
  const selectedImage = pageImages.find((image) => image.title === title)
  if (selectedImage) {
    return selectedImage.gatsbyImageData
  }
  return undefined
}

const DisabilityServicesAdvisoryCouncilPage = ({
  location,
  data: {
    site: { siteMetadata },
    pageResources
  }
}: Props) => {
  const resources = pageResources.edges[0].node
  const { pageImages } = resources

  return (
    <div>
      <WrappedHelmet
        title={`Disability Services Advisory Council | ${siteMetadata.title}`}
        description="Read about Disability Services Advisory Council (DSAC)."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
      />
      <Layout>
        <Banner
          title="Disability Services Advisory Council (DSAC)"
          breadcrumbs={bannerBreadcrumbs}
        />
        <Container>
          <Row>
            <Box>
              <Heading size={2}>
                About Disability Services Advisory Council
              </Heading>
              <p>
                The Disability Services Advisory Council (DSAC) is a Life
                Without Barriers initiative. The council is responsible for
                gathering valuable service feedback from the people we support.
                That feedback is used to help us improve, adapt and remain
                focused on the people we support.{" "}
              </p>
              <Heading size={2} topMargin="standard">
                How it works
              </Heading>
              <p>
                Members of DSAC run groups in their regions to hear from people
                who use our services. They share what they’ve learnt at the
                bi-annual DSAC meetings, which includes Life Without Barriers
                Board members and the Chief Executive Officer, and advocate for
                changes.
              </p>
              <p>
                DSAC group participants are expected to uphold our values and:
              </p>
              <ol>
                <li>Listen to what other people say </li>
                <li className="pt2">Work as part of a team </li>
                <li className="pt2">
                  Speak for the local group at state and national meetings
                </li>
              </ol>
              <Heading size={2} topMargin="standard">
                Our national DSAC members
              </Heading>
              <ul className="list flex flex-wrap pl0 mt4">
                {dsacMembers.map((member, i) => {
                  const memberImage = getImageFromPageResources(
                    member.imageTitle,
                    pageImages
                  )
                  return (
                    <StyledLi className="w-100 w-50-m w-25-l pv3" key={i}>
                      {memberImage && (
                        <GatsbyImage
                          image={getGatsbyImage(memberImage)}
                          alt=""
                        />
                      )}
                      <h3 className="fw8 tc mt4 ts-display-4">{member.name}</h3>
                      <p className="mt2 tc b color-lwb-theme-darker">
                        {member.position}
                      </p>
                    </StyledLi>
                  )
                })}
              </ul>
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row>
              <Box>
                <ResourcesTile
                  className="lwb-shadow w-100 w-75-l"
                  name="DSAC Recommended Resources"
                  icon={
                    <IconSheets height="25" color={disabilityTheme.medium} />
                  }
                  resources={dsacResources.map(({ name, url }) => ({
                    name,
                    reference: url
                  }))}
                />
              </Box>
            </Row>
          </Container>
        </Section>
        <Section background="bg-hex-image">
          <Container>
            <Row>
              <Box className="layout-readable">
                <Heading size={2}>For more information about the DSAC</Heading>
                <p>
                  The DSAC is guided by the United Nations Convention on the
                  rights of Persons with Disabilities. You can find out more
                  about the DSAC by emailing Anne McKinstray.
                </p>
                <div className="pt3 pt0-l">
                  <ContactUsEmailLink
                    text="anne.mckinstray@lwb.org.au"
                    link="mailto:anne.mckinstray@lwb.org.au"
                  />
                </div>
              </Box>
            </Row>
          </Container>
        </Section>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row>
              <Box>
                <NavigationTiles
                  heading={
                    <Heading size={2} className="color-lwb-theme-darker tc">
                      Learn more about the Life Without Barriers difference
                    </Heading>
                  }
                  currentPathname={location.pathname}
                  pages={[
                    { ...YourRights, actionLabel: "Find out more" },
                    {
                      ...OurApproachToDisabilityServices,
                      actionLabel: "Find out more"
                    },
                    { ...OurDisabilityServices, actionLabel: "Find out more" }
                  ]}
                />
              </Box>
            </Row>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    uncrEasyEnglish: allFile(
      filter: { name: { eq: "Easy-English-UN-Rights" } }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
    pageResources: allContentfulPageResources(
      filter: { title: { eq: "disability-services-advisory-council" } }
    ) {
      edges {
        node {
          title
          pageImages {
            ...SmallRemoteContentfulImageAsset
          }
        }
      }
    }
  }
`

export default DisabilityServicesAdvisoryCouncilPage
